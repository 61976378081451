import {Link} from 'gatsby';
import styled from 'styled-components';
import CustomLink from '../../../atoms/CustomLink';

export const PageContainer = styled.div`
  width: 100%;
  background-color: ${({backgroundColor}) => backgroundColor};
  padding: 5rem 0;
  margin-top: -12px;
`;

export const Container = styled.div`
  width: 100%;
  max-width: 50rem;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto;
  grid-gap: 1.9rem;

  @media (max-width: 1024px) {
    padding: 0 40px;
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 400px) {
    grid-gap: 1rem;
  }
`;

export const GalleryItem = styled(CustomLink)`
  display: block;
  text-decoration: none;
  width: 100%;
  height: 100%;
  position: relative;
  cursor: pointer;
  box-shadow: 0 0 37px 7px rgba(8, 9, 8, 0.11);

  &:hover {
    div {
      opacity: 1;
    }
  }
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const Darken = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px;
  transition: 0.3s;
  opacity: 0;
  padding: 15px;
`;

export const Title = styled.h4`
  font-family: LATOBLACK, sans-serif;
  font-size: 0.7rem;
  text-transform: uppercase;
  letter-spacing: 0.67px;
  color: #ffffff;
  font-weight: 400;
  text-align: center;
`;

export const GalleryItemMobile = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
`;

export const TitleMobile = styled.h4`
  font-family: 'LATO', sans-serif;
  font-size: 0.7rem;
  text-transform: uppercase;
  letter-spacing: 0.67px;
  color: #ffffff;
  font-weight: 500;
  text-align: center;
  margin-top: 0.4rem;
`;
