import React from 'react';
import {isMobile} from 'react-device-detect';
import {API_ASSETS_PREFIX} from '../../../globals';
import {
  Container,
  Darken,
  GalleryItem,
  Image,
  PageContainer,
  Title,
  GalleryItemMobile,
  TitleMobile,
} from './styles';

const createItems = (filtredData, location) => {
  return filtredData.map((el) => (
    <GalleryItem to={`/${location}/${el.titlePage}`}>
      <Image src={API_ASSETS_PREFIX + el.thumbnail} />
      <Darken>
        <Title>{el.title[location]}</Title>
      </Darken>
    </GalleryItem>
  ));
};

const createItemsMobile = (filtredData, location) => {
  return filtredData.map((el) => (
    <GalleryItemMobile>
      <GalleryItem to={`/${location}/${el.titlePage}`}>
        <Image src={API_ASSETS_PREFIX + el.thumbnail} />
      </GalleryItem>
      <TitleMobile>{el.title[location]}</TitleMobile>
    </GalleryItemMobile>
  ));
};

const CategoryPage = ({bgColor, data, categoryId, location}) => {
  const filtredData = data.filter((el) => el.category === categoryId);
  return (
    <PageContainer backgroundColor={bgColor}>
      <Container>
        {isMobile
          ? createItemsMobile(filtredData, location)
          : createItems(filtredData, location)}
      </Container>
    </PageContainer>
  );
};

export default CategoryPage;
